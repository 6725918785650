
  import React from 'react';
  import { getWidgetWrapper } from '@wix/yoshi-flow-editor/runtime/esm/WidgetWrapper.js';
  import Widget from '/home/builduser/work/9b222164c6be38e0/packages/communities-forum-member-pages/src/components/MemberComments/Widget/index.tsx';

  import {
    withLeanStyles
  } from '@wix/native-components-infra';

  
var initI18n = null;

  
const multilingualDisabled = false;

  
import { createExperiments, createWidgetExperiments } from '@wix/yoshi-flow-editor/runtime/esm/experiments';
    

  
var I18nextProvider = React.Fragment;


      import { PureExperimentsProvider } from '@wix/yoshi-flow-editor';
      var ExperimentsProvider = React.Fragment;
      

import { BILoggerProvider } from '@wix/yoshi-flow-editor/runtime/esm/react/BILogger/BILoggerProvider';

import { FedopsLoggerProvider } from '@wix/yoshi-flow-editor/runtime/esm/react/FedopsLoggerProvider';

import { HttpProvider } from '@wix/yoshi-flow-editor';

import { TPAComponentsProvider } from 'wix-ui-tpa';

import { BaseUiEnvironmentProviderWrapper } from '@wix/yoshi-flow-editor/runtime/esm/react/BaseUiEnvironmentProviderWrapper';

var providers = {
  I18nextProvider,
  ExperimentsProvider,
  PureExperimentsProvider,
  BILoggerProvider,
  FedopsLoggerProvider,
  HttpProvider,
  TPAComponentsProvider,
  BaseUiEnvironmentProviderWrapper,
}


  import stylesParams from '/home/builduser/work/9b222164c6be38e0/packages/communities-forum-member-pages/src/components/MemberComments/stylesParams.ts';

  var sentryConfig = {
      DSN: 'https://0e6a29e4756740a8a63493e912ba2174@sentry.wixpress.com/2420',
      id: '0e6a29e4756740a8a63493e912ba2174',
      projectName: 'communities-forum-member-pages',
      teamName: 'forum',
      errorMonitor: true,
    };

  var translationsConfig = {"enabled":false,"icuEnabled":false};

  var UserComponent = getWidgetWrapper({
      initI18n,
      withStylesHoc: withLeanStyles,
      createExperiments,
      createWidgetExperiments,
      providers,
    }, Widget, {
    multilingualDisabled,
    sentryConfig,
    translationsConfig,
    stylesParams,
    componentId: '14f51b00-3ada-272e-b6db-34d5da9dd623',
    name: 'MemberComments',
    withErrorBoundary: true,
    localeDistPath: 'assets/locales',
  });

  
    import { hot } from '@wix/component-hot-loader';
    UserComponent = hot(module, UserComponent);
  

  const loadChunks = Widget.loadChunks;

  export default {
    
    component: UserComponent,
    loadChunks
  };
